<template>
  <div class="responsive-iframe-container">
    <iframe 
      src="https://lookerstudio.google.com/embed/reporting/360d9d01-9456-4cc9-a825-186c63df808c/page/CYFdE"
      frameborder="0" 
      style="border:0" 
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.responsive-iframe-container {
  position: relative;
  width: 100%;
  /* 16:9 aspect ratio: (9/16)*100 = 56.25% */
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
